import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import { formatMonetario } from '.'
import { valoresPacotesTreinamentos } from './planos/valoresPacotesTreinamentos'
import { valoresPlanosSischef } from './planos/valoresPlanosSischef'

import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, MenuItem, Select, TextField, Tooltip, Typography, makeStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import AssignmentIcon from '@material-ui/icons/Assignment'
import CloseIcon from '@material-ui/icons/Close'
import RemoveIcon from '@material-ui/icons/Remove'
import StarsIcon from '@material-ui/icons/Stars'

const useStyles = makeStyles((theme) => {
    const defaultBorder = '1px solid ' + theme.palette.divider

    return {
        dialogTitleWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },

        dialogContent: {
            padding: 0,
            borderTop: defaultBorder,
            borderBottom: defaultBorder,
        },

        resumeWrapper: {
            width: '100%',
        },

        resumeTitle: {
            display: 'flex',
            alignItems: 'center',
            padding: '13px',
            borderBottom: defaultBorder,
            borderTop: defaultBorder,
            minHeight: '75px',
        },

        resumeContent: {
            padding: '13px',
            overflow: 'auto',
        },

        sideBar: {
            minWidth: '200px',
            borderRight: defaultBorder,
        },

        packagesTitle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            padding: '11px 13px',
            borderBottom: defaultBorder,
            minHeight: '75px',
        },

        listItem: {
            '& + &': {
                borderTop: defaultBorder,
            },

            '& .MuiListItem-root': {
                padding: '21px 21px 21px 13px',
            }
        },

        listItemOutros: {
            display: 'flex',
            alignItems: 'center',
            minHeight: '99px',

            '& + &': {
                borderTop: defaultBorder,
            }
        },

        qtyInput: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 40px',
            marginBottom: '21px',
        },

        star: {
            position: 'absolute',
            top: '8px',
            right: '8px',
            color: 'gold',
        }
    }
})

function PackageListItem({ pacote, checkedPackage, onChecked, onQtyHours }) {
    const classes = useStyles()
    const checked = checkedPackage?.id
    const selected = pacote.bloqueado ? true : checked === pacote.id

    function handleChange() {
        if (pacote.bloqueado || pacote.customizavel) {
            return
        }

        onChecked(pacote.id)
    }

    return (
        <Box className={classes.listItem}>
            <ListItem style={{ paddingBottom: pacote.customizavel ? '0' : '21px' }}>
                {pacote.id === 'FULL' && (
                    <Tooltip title="Melhor opção" placement="top">
                        <StarsIcon className={classes.star} />
                    </Tooltip>
                )}
                {!pacote.customizavel && (
                    <ListItemIcon>
                        <Checkbox
                            value={pacote.id}
                            checked={selected}
                            disabled={pacote.bloqueado}
                            onChange={handleChange}
                        />
                    </ListItemIcon>
                )}
                <ListItemText
                    style={{ textAlign: pacote.customizavel ? 'center' : 'left' }}
                    primary={pacote.descricao}
                    secondary={!pacote.customizavel ? pacote.horas + ' Horas' : `${formatMonetario(pacote.valor || 0)}/hora`}
                />
            </ListItem >
            {pacote.customizavel && (
                <Box className={classes.qtyInput}>
                    <IconButton size="small" onClick={() => onQtyHours('remove')}>
                        <RemoveIcon />
                    </IconButton>
                    {pacote.horas}
                    <IconButton size="small" onClick={() => onQtyHours('add')}>
                        <AddIcon />
                    </IconButton>
                </Box>
            )}
        </Box>
    )
}

function SectionTitle({ title, textToClipboard }) {
    const classes = useStyles()

    function copyToClipboard() {
        navigator.clipboard.writeText(textToClipboard)
    }

    return (
        <Box className={classes.resumeTitle}>
            <Typography variant="h6">
                {title}
            </Typography>

            <Box ml={1}>
                <Tooltip title="Copiar resumo" placement="right">
                    <IconButton onClick={copyToClipboard}>
                        <AssignmentIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    )
}

function InsideModule({ moduloInterno, onHandleInsideModule }) {
    const classes = useStyles()

    return (
        <Box className={classes.listItemOutros}>
            <ListItem>
                <ListItemIcon>
                    {moduloInterno.tipo === 'NUMERO' ? (
                        <Typography variant="h5" >
                            {moduloInterno.quantidade > 0 ? moduloInterno.quantidade : '--'}
                        </Typography>
                    ) : (
                        <Checkbox
                            edge="start"
                            checked={moduloInterno.selecionado}
                            onChange={() => onHandleInsideModule(moduloInterno, 'check')} />
                    )}
                </ListItemIcon>

                <ListItemText secondary={`+ ${formatMonetario(moduloInterno.valorAtivacao || 0)}`}>
                    {moduloInterno.descricao}
                </ListItemText>

                {moduloInterno.tipo === 'NUMERO' ? (
                    <ListItemSecondaryAction>
                        <IconButton size="small" onClick={() => onHandleInsideModule(moduloInterno, 'add')}><AddIcon /></IconButton> <br />
                        <IconButton size="small" onClick={() => onHandleInsideModule(moduloInterno, 'remove')}><RemoveIcon /></IconButton>
                    </ListItemSecondaryAction>
                ) : null}
            </ListItem>
        </Box>
    )
}

export function TrainingPackageDialog({ open, tipo, plan, setOpen, planos, onChangePlanos }) {
    const params = useParams()
    const classes = useStyles()
    const [totais, setTotais] = useState({})
    const [pacotes, setPacotes] = useState()
    const [outrosSischef, setOutrosSischef] = useState()
    const [frequency, setFrequency] = useState(12)
    const [discount, setDiscount] = useState(0)
    const [resumoPlanoEscolhido, setResumoPlanoEscolhido] = useState([])
    const [resumoTotalPlanoEscolhido, setResumoTotalPlanoEscolhido] = useState([])

    const isFull = plan === 'full'

    let checkedPackage = pacotes && plan ? pacotes.pacotes.find(pacote => pacote.id === plan.toUpperCase()) : {}
    if (!checkedPackage?.id && pacotes) {
        checkedPackage = pacotes.pacotes.find(pacote => pacote.bloqueado)
    }

    useEffect(() => {
        setPacotes(valoresPacotesTreinamentos)

        if (valoresPlanosSischef) {
            const gruposExtras = valoresPlanosSischef.grupos.find(grupo => grupo.id === 'GRUPO_EXTRAS')
            const moduloOutros = gruposExtras.modulos.find(modulo => modulo.id === 'Outros')

            setOutrosSischef(moduloOutros)
        }
    }, [])

    // não deixa fechar caso for full
    useEffect(() => {
        if (isFull) {
            setOpen(true)
        }
    }, [open, isFull])

    useEffect(() => {
        if (!pacotes || !open) return

        let totalHoras = 0
        let totalFrete = 0
        let totalMensalidade = 0

        const modulesResume = []

        if (isFull) {
            // TODO: futuramente pegar direto do planoSischef para ficar mais dinâmico
            modulesResume.push(`Acesso completo a todas as funções do sistema`)
            modulesResume.push(`Sem necessidade de pagar por módulos adicionais`)
            modulesResume.push(`Atualizações automáticas com novos recursos incluídos`)

            setResumoPlanoEscolhido(modulesResume)
            createTotalResume(checkedPackage.valor, checkedPackage.horas, totalFrete)
            setTotais({ horas: checkedPackage.horas })
            return
        }

        planos.grupos.map(grupo => {
            grupo.modulos.map(modulo => {
                if (modulo.tipo === 'NUMERO') {
                    if (modulo.quantidade > 0) {
                        modulesResume.push(`${modulo.quantidade}x ${modulo.descricao}`)
                        totalMensalidade += (modulo.quantidade * modulo.valorMensalidade)
                        totalHoras += modulo.horas ? (modulo.horas * modulo.quantidade) : 0
                    }
                } else {
                    if (modulo.selecionado) {
                        modulesResume.push(modulo.descricao)
                        totalMensalidade += modulo.valorMensalidade
                        totalHoras += modulo.horas ? modulo.horas : 0
                    }
                }

                if (modulo.selecionado) {
                    modulo.modulosInternos.map(moduloInterno => {

                        if (moduloInterno.tipo === 'NUMERO') {
                            if (moduloInterno.quantidade > 0) {
                                modulesResume.push(`+ ${moduloInterno.quantidade}x ${moduloInterno.descricao}`)
                                totalMensalidade += (moduloInterno.quantidade * moduloInterno.valorMensalidade)
                                totalHoras += moduloInterno.horas ? (moduloInterno.horas * moduloInterno.quantidade) : 0
                                totalFrete += (moduloInterno.valorFrete || 0)
                            }
                        } else {
                            if (moduloInterno.selecionado) {
                                modulesResume.push('+ ' + moduloInterno.descricao)
                                totalMensalidade += moduloInterno.valorMensalidade
                                totalHoras += moduloInterno.horas ? moduloInterno.horas : 0
                            }
                        }
                    })
                }
            })
        })

        // o valor minimo seria o valor do pacote, acima disso ele muda
        if (totalMensalidade < checkedPackage.valor && plan === 'sugerido') {
            totalMensalidade = checkedPackage.valor
        }

        setResumoPlanoEscolhido(modulesResume)

        createTotalResume(totalMensalidade, totalHoras, totalFrete)

        setTotais({ horas: totalHoras })
    }, [pacotes, plan, planos, frequency, open, discount])

    function getPercentualDesconto(frequency) {
        if (frequency === 18) {
            return 10
        } else if (frequency === 24) {
            return 15
        }

        return 0
    }

    // cria o resumo dos totais da calculadora
    function createTotalResume(totalMensalidade, totalHoras, totalFrete) {
        const modulesResumeTotal = []
        const percentualDesconto = getPercentualDesconto(frequency)
        const horasExtras = pacotes.pacotes.find(item => item.customizavel)
        const economia = totalMensalidade - totalMensalidade * (1 - (percentualDesconto / 100))

        let total = 0
        let pacotesEscolhidos = ''
        let totalHorasValor = 0
        let totalHorasTreinamento = totalHoras || 0

        function pushMensalidade(totalM, discountM) {
            if (frequency > 12) {
                modulesResumeTotal.push(`Valor da mensalidade de R$ ${formatMonetario(totalM)} por R$ ${formatMonetario(totalM * (1 - (discountM / 100)))}`)
                return
            }

            modulesResumeTotal.push(`Valor da mensalidade: R$ ${formatMonetario(totalMensalidade)}`)
        }

        modulesResumeTotal.push('Resumo Treinamento & Setup')

        pacotes.pacotes.map(pacote => {
            if (!pacote.selecionado) return

            if (pacote.id === 'EAD_SETUP') {
                total += pacote.valor
            }

            pacotesEscolhidos += !pacotesEscolhidos ? pacote.descricao : ' + ' + pacote.descricao
        })

        let horasAdicionaisTxt = ''
        if (horasExtras.horas > 0) {
            // total += horasExtras.valor * horasExtras.horas
            totalHorasValor += horasExtras.valor * horasExtras.horas
            totalHorasTreinamento += horasExtras.horas
            horasAdicionaisTxt = ` + ${horasExtras.horas}x Horas adicionais`
        }

        modulesResumeTotal.push('Pacote escolhido: ' + pacotesEscolhidos + horasAdicionaisTxt)
        modulesResumeTotal.push('</br>')

        const othersTotal = tipo === 'sischef' ? getOthersTotal() : 0
        const newTotal = total + othersTotal + totalHorasValor

        modulesResumeTotal.push(`Setup & Implantação: R$ ${formatMonetario(total)}`)

        if (totalHorasValor) {
            modulesResumeTotal.push(`Treinamento: R$ ${formatMonetario(totalHorasValor)}`)
        }

        if (othersTotal) {
            modulesResumeTotal.push(`${outrosSischef.descricao}: R$ ${formatMonetario(othersTotal)}`)
        }

        let discountValue = 0
        if (discount > 0) {
            discountValue = newTotal - (newTotal * (1 - (discount / 100)))
            modulesResumeTotal.push(`Desconto total: -R$ ${formatMonetario(discountValue)}`)
        }

        if (discount || othersTotal || totalHorasValor) {
            modulesResumeTotal.push(`Total geral: R$ ${formatMonetario(newTotal - discountValue)}`)
        }

        modulesResumeTotal.push('</br>')
        modulesResumeTotal.push('Resumo Mensalidade')
        modulesResumeTotal.push(`Período do contrato ${frequency} meses`)

        if (checkedPackage.id === 'FULL' || checkedPackage.id === 'SUGERIDO') {
            if (checkedPackage.desconto) {
                const valueWithDiscount = totalMensalidade * (1 - ((checkedPackage.desconto + percentualDesconto) / 100))
                modulesResumeTotal.push(`Valor da mensalidade: <del>R$ ${formatMonetario(totalMensalidade)}</del> por R$ ${formatMonetario(valueWithDiscount)} (${formatMonetario(checkedPackage.desconto + percentualDesconto)}%)`)
            } else {
                pushMensalidade(checkedPackage.valor, percentualDesconto)
            }
        } else {
            pushMensalidade(totalMensalidade, percentualDesconto)
        }

        if (economia) {
            modulesResumeTotal.push(`Economia de R$ ${formatMonetario(economia)} por mês`)
        }

        if (params.tipo === 'qrpedir') {
            modulesResumeTotal.push(`Frete Cavaletes ${formatMonetario(totalFrete)}`)
        }

        setResumoTotalPlanoEscolhido(modulesResumeTotal)
    }

    // carrega o modulo 'Outros'
    function getOthers() {
        const copyPlanos = { ...planos }
        const gruposExtras = copyPlanos.grupos.find(grupo => grupo.id === 'GRUPO_EXTRAS')

        return gruposExtras.modulos.find(modulo => modulo.id === 'Outros')
    }

    // calcula o total dos modulos internos do modulo de 'Outros'
    function getOthersTotal() {
        const moduloOutros = getOthers()

        return moduloOutros.modulosInternos.reduce((acc, item) => {
            if (!item.quantidade && !item.selecionado) {
                return acc
            }

            if (item.tipo === 'NUMERO') {
                return acc + (item.valorAtivacao * item.quantidade)
            }

            return acc + item.valorAtivacao
        }, 0)
    }

    function handleCheck(id) {
        const copyPacotes = { ...pacotes }

        if (checkedPackage?.id === id) {
            copyPacotes.pacotes.map(pacote => pacote.selecionado = pacote.bloqueado || false)
            setPacotes(copyPacotes)
            return
        }

        copyPacotes.pacotes.map(pacote =>
            pacote.selecionado = pacote.bloqueado || pacote.id === id)

        setPacotes(copyPacotes)
    }

    function handleQtyHours(event) {
        const copyPacotes = { ...pacotes }

        copyPacotes.pacotes.map(pacote => {
            if (!pacote.customizavel) return

            if (event === 'remove' && pacote.horas > 0) {
                pacote.horas -= 1
            } else if (event === 'add') {
                pacote.horas += 1
            }
        })

        setPacotes(copyPacotes)
    }

    function handleDiscount(value) {
        setDiscount(value)
    }

    function handleInsideModule(moduloInterno, event) {
        const copyPlanos = { ...planos }
        const gruposExtras = copyPlanos.grupos.find(grupo => grupo.id === 'GRUPO_EXTRAS')
        const moduloOutros = gruposExtras.modulos.find(modulo => modulo.id === 'Outros')

        moduloOutros.modulosInternos.map(item => {
            if (item.id !== moduloInterno.id) return

            if (event === 'remove' && item.quantidade > 0) {
                item.quantidade -= 1
            } else if (event === 'add') {
                item.quantidade += 1
            } else if (event === 'check') {
                item.selecionado = !item.selecionado
            }
        })

        moduloOutros.selecionado = moduloOutros.modulosInternos.some(item => item.quantidade > 0 || item.selecionado)

        onChangePlanos(copyPlanos)
    }

    function copyAllToClipboard() {
        const textToClipboard = [...resumoPlanoEscolhido, '\n', ...resumoTotalPlanoEscolhido]
            .join('\n').replace(/<(\/|\w)*>/g, '')

        navigator.clipboard.writeText(textToClipboard)
    }

    if (!pacotes) {
        return null
    }

    return (
        <Dialog
            fullWidth
            open={open}
            maxWidth="lg"
            onClose={() => setOpen(false)}>

            <DialogTitle>
                <Box className={classes.dialogTitleWrapper}>
                    <Typography variant="body2">
                        Sugestão de {totais.horas} horas para implantação e treinamento
                    </Typography>
                    {!isFull && (
                        <IconButton size="small" onClick={() => setOpen(false)}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    )}
                </Box>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <Box display="flex" height="100%">
                    {tipo === 'sischef' && (
                        <Box className={classes.sideBar}>
                            {/* <Typography className={classes.packagesTitle}>
                                Customização
                            </Typography> */}
                            <List disablePadding>
                                {pacotes.pacotes.filter(pacote => pacote.customizavel).map(pacote => (
                                    <PackageListItem
                                        key={pacote.id}
                                        pacote={pacote}
                                        checked={checkedPackage?.id}
                                        checkedPackage={checkedPackage}
                                        onChecked={handleCheck}
                                        onQtyHours={handleQtyHours}
                                    />
                                ))}
                            </List>
                            <Divider />
                            <Typography className={classes.packagesTitle}>
                                {outrosSischef.descricao}
                            </Typography>
                            <List disablePadding>
                                {outrosSischef.modulosInternos.map(moduloInterno => (
                                    <InsideModule
                                        key={moduloInterno.id}
                                        moduloInterno={moduloInterno}
                                        onHandleInsideModule={handleInsideModule} />
                                ))}
                            </List>
                        </Box>
                    )}

                    <Box className={classes.resumeWrapper}>
                        <Box display="flex" alignItems="center">
                            <Box px={2} py="13px">
                                <TextField
                                    type="number"
                                    label="Desconto"
                                    value={discount}
                                    onInput={(event) => handleDiscount(event.target.value)}
                                    inputProps={{ style: { textAlign: 'right' } }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                />
                            </Box>
                            <Box px={2} py="13px">
                                <FormControl>
                                    <InputLabel>Periodicidade</InputLabel>
                                    <Select
                                        value={frequency}
                                        label="Periodicidade"
                                        onChange={(event) => setFrequency(event.target.value)}>
                                        <MenuItem value={12}>12 meses (padrão)</MenuItem>
                                        <MenuItem value={18}>18 meses 10% desc. mensalidade</MenuItem>
                                        <MenuItem value={24}>24 meses 15% desc. mensalidade</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Tooltip title="Copiar resumo geral" placement="right">
                                <IconButton onClick={copyAllToClipboard}>
                                    <AssignmentIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Box>

                        <SectionTitle
                            title="Resumo do plano escolhido"
                            textToClipboard={resumoPlanoEscolhido.join('\n')} />
                        <Box className={classes.resumeContent}>
                            {resumoPlanoEscolhido.length ? resumoPlanoEscolhido.map((line, index) => (
                                <React.Fragment key={index}>{line} <br /></React.Fragment>
                            )) : (
                                'Nenhum plano selecionado'
                            )}
                        </Box>

                        <SectionTitle
                            title="Resumo treinamento setup"
                            textToClipboard={resumoTotalPlanoEscolhido.join('\n')} />
                        <Box className={classes.resumeContent}>
                            {resumoTotalPlanoEscolhido.map((line, index) => (
                                <div key={index} dangerouslySetInnerHTML={{ __html: line }} />
                            ))}
                        </Box>
                    </Box>
                </Box>
            </DialogContent>

            {!isFull && (
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<CloseIcon />}
                        onClick={() => setOpen(false)}>
                        Fechar
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    )
}