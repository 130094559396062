import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Calculadora from "./calculadora"
import Contrato from "./contrato"
import Main from "./main"
import CadastroForm from "./cadastramento"
import { PlanSelecion } from "./calculadora/PlanSelection"


const Routes = () => (
  <BrowserRouter>
    <Switch>

      <Route exact path="/" component={Main} />
      <Route exact path="/contrato" component={Contrato} />
      <Route exact path="/calculadora/:tipo" component={Calculadora} />
      <Route exact path="/cadastro-sischef" component={CadastroForm} />
      <Route exact path="/cadastro/:sistema" component={CadastroForm} />
      <Route exact path="/selecao-de-plano/:sistema" component={PlanSelecion} />

    </Switch>
  </BrowserRouter>
);

export default Routes;