export const PLANOS_SISCHEF = [
    {
        id: 'customizado',
        title: 'Customizado',
        hours: 2,
        discount: 0,
        implantationPrice: 499.9,
        modules: [],
        description: `
            Você pode montar seu próprio sistema escolhendo os
            módulos individualmente ou optar por um dos pacotes. Se
            precisar de um módulo extra, é só adicionar!`
    },
    {
        id: 'sugerido',
        title: 'Sugerido',
        hours: 2,
        discount: 19.34,
        montlyPrice: 458.65,
        implantationPrice: 499.9,
        modules: [
            {
                id: 'START',
                description: 'Plano Essencial',
                internalModules: [
                    'Cadastro pessoas',
                    'Cadastro de produtos',
                    'Cadastro de grupos',
                    'Primeiro cadastro do cardápio',
                    'Configurações iniciais',
                ]
            },
            {
                id: 'PDV Online',
                description: 'PDV Online',
                internalModules: [
                    'Pedido de comanda e Mesa',
                    'Pedido delivery + balcão',
                    'Pedidos Smartphone / Tablet',
                    'IntegracaoAutoAtendedimentoBalancaOnLine',
                ]
            },
            {
                id: 'NOTIFICACAO',
                description: 'Notificação nas áreas de produção ( Incluso 2 áreas de notificação )',
                internalModules: [
                    'IMPRESSO',
                ]
            },
            {
                id: 'ESTOQUE',
                description: 'Estoque',
                internalModules: [
                    'Composição produtos',
                    'Importação XML - Busca SEFAZ',
                ]
            },
            {
                id: 'FINANCEIRO',
                description: 'Financeiro (Incluso 1 Controle de Caixa) ',
                internalModules: [
                    'Controle de Caixa',
                    'Controle Cartões (Credito/Debito)',
                ]
            },
            {
                id: 'FISCAL',
                description: 'Fiscal',
                internalModules: [
                    'Emissor de Nfce e Nfe - Simples Nacional',
                ]
            },
            {
                id: 'Integrações APPs ( PDV Online )',
                description: 'Integrações APPs ( PDV Online )',
                internalModules: [
                    'iFood',
                    'iFood',
                ]
            }
        ],
        description: `
            O pacote já inclui os módulos mais utilizados pelos clientes
            e foi estruturado para garantir o melhor custo-benefício.
            Como o desconto foi aplicado ao conjunto, não é possível
            remover módulos, mas podemos adicionar qualquer
            funcionalidade extra conforme sua necessidade!`
    },
    {
        id: 'full',
        title: 'Full',
        hours: 2,
        discount: 0,
        montlyPrice: 699.9,
        implantationPrice: 499.9,
        modules: [
            {
                id: 1,
                internalModules: ['Acesso completo a todas as funções do sistema']
            },
            {
                id: 2,
                internalModules: ['Sem necessidade de pagar por módulos adicionais']
            },
            {
                id: 3,
                internalModules: ['Atualizações automáticas com novos recursos incluídos']
            },
        ],
        limitations: [
            {
                id: 1,
                description: '1 TEF'
            },
            {
                id: 2,
                description: '5 integrações'
            },
            {
                id: 3,
                description: '1 Loja Analytics'
            },
            {
                id: 4,
                description: '5 Locais de Notificação'
            },
            {
                id: 5,
                description: '5 Caixas'
            },
        ],
        description: `
            Se você deseja ter o sistema completo, sem qualquer
            limitação, o Pacote Full é a melhor escolha! Com ele, você
            terá acesso a todos os módulos e integrações disponíveis,
            sem precisar pagar nada a mais por funcionalidades adicionais.`
    },
]