export const valoresPacotesTreinamentos = {
    tipo: 'pacote-treinamento',
    tituloPrincipal: "Pacote de Treinamentos",
    pacotes: [
        {
            id: "EAD_SETUP",
            descricao: "EAD & Setup",
            horas: 2,
            valor: 499.90,
            valorHora: 250.00,
            desconto: 0,
            bloqueado: true,
            selecionado: true,
            customizavel: false,
        },
        {
            id: "SUGERIDO",
            descricao: "Sugerido",
            horas: 5,
            valor: 458.65,
            desconto: 19.35,
            valorHora: 250.00,
            bloqueado: false,
            selecionado: false,
            customizavel: false,
        },
        {
            id: "FULL",
            descricao: "Full",
            horas: 10,
            valor: 699.90,
            desconto: 0,
            valorHora: 150.00,
            bloqueado: false,
            selecionado: false,
            customizavel: false,
        },
        {
            id: "CUSTOMIZADO",
            descricao: "Horas adicionais",
            horas: 0,
            valor: 150.00,
            bloqueado: false,
            selecionado: false,
            customizavel: true,
        },
    ]
}