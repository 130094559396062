import React, { useState } from 'react'

import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Paper, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import ListIcon from '@material-ui/icons/List'
import CloseIcon from '@material-ui/icons/Close'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { formatMonetario } from '.'
import { PLANOS_SISCHEF } from './planos/planosSischef'

function PlanPrice({ price, discount }) {
    let newPrice = price ? 'R$ ' + formatMonetario(price) : 'X,XX'

    if (price && discount) {
        const priceWithDiscount = price * (1 - (discount / 100))
        newPrice = `R$ ${formatMonetario(priceWithDiscount)}`
    }

    return (
        <Box pb={1}>
            {!!discount && (
                <Box>
                    <Typography style={{ paddingLeft: 4 }} color="primary" variant="caption">
                        de R$ {formatMonetario(price)} por
                    </Typography>
                </Box>
            )}
            <Typography component="span" color="primary" variant="h5" style={{ fontWeight: '600' }}>
                {newPrice}
            </Typography>
            <Typography style={{ paddingLeft: 4 }} color="primary" component="span" variant="body1">
                mês
            </Typography>
            {!!discount && (
                <Box>
                    <Typography style={{ paddingLeft: 4 }} variant="caption">
                        ({formatMonetario(discount)}% de desconto)
                    </Typography>
                </Box>
            )}
        </Box>
    )
}

function PlanDialog({ open, onClose, modules, limitations }) {
    return (
        <Dialog
            open={open}
            onClose={onClose}>

            <DialogTitle>
                Módulos inclusos
            </DialogTitle>

            <DialogContent>
                {modules.map(item => (
                    <Box key={item.id} mb={2}>
                        {!!item.description && (
                            <Typography variant="caption">
                                {item.description}
                            </Typography>
                        )}
                        {!!item.internalModules && item.internalModules.map((item, index) => (
                            <Typography key={index} variant="body1">
                                + {item}
                            </Typography>
                        ))}
                    </Box>
                ))}
                {!!limitations && (
                    <>
                        <Divider />
                        <Box mt={2}>
                            <Typography variant="h6">
                                Limitações operacionais
                            </Typography>
                            {limitations.map(limitation => (
                                <Box key={limitation.id} py={1}>
                                    <Typography variant="body1">
                                        - {limitation.description}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </>
                )}
            </DialogContent>

            <DialogActions>
                <Button
                    startIcon={<CloseIcon />}
                    onClick={onClose}>
                    Fechar
                </Button>
            </DialogActions>

        </Dialog>
    )
}

function PlanCard({ plan }) {
    const history = useHistory()
    const [openDialog, setOpenDialog] = useState(false)
    const { hours = 2, modules = [], limitations, implantationPrice = 499.9, montlyPrice = 0 } = plan

    function handleSelect() {
        let query = ''

        if (plan.id !== 'customizado') {
            query = '?plan=' + plan.id
        }

        history.push('/calculadora/sischef' + query)
    }

    return (
        <>
            <Box
                p={2}
                flex={1}
                display="flex"
                flexDirection="column"
                gridGap="8px">
                {props => (
                    <Paper variant="outlined" {...props}>
                        <Typography variant="h6">
                            {plan.title}
                        </Typography>

                        <Box py={3} flex={1}>
                            <PlanPrice
                                price={montlyPrice}
                                discount={plan.discount} />

                            <Typography variant="body1">
                                Setup & Implantação: R$ {formatMonetario(implantationPrice)}
                            </Typography>

                            <Typography variant="caption">
                                *valor referido a treinamento de {hours} horas
                            </Typography>
                        </Box>

                        {!!modules.length && (
                            <Button
                                color="primary"
                                variant="outlined"
                                startIcon={<ListIcon />}
                                onClick={() => setOpenDialog(true)}>
                                Listar módulos
                            </Button>
                        )}

                        <Button
                            color="primary"
                            variant="contained"
                            startIcon={<CheckIcon />}
                            onClick={handleSelect}>
                            Selecionar
                        </Button>

                        {!!plan.description && (
                            <>
                                <Divider />
                                <Typography variant="caption">
                                    {plan.description}
                                </Typography>
                                {!!limitations && (
                                    <Typography variant="caption">
                                        {'Limitações operacionais: '}
                                        {limitations.map(limitation => (
                                            <React.Fragment key={limitation.id}>
                                                {`${limitation.description}; `}
                                            </React.Fragment>
                                        ))}
                                    </Typography>
                                )}
                            </>
                        )}
                    </Paper>
                )}
            </Box>

            {!!modules.length && (
                <PlanDialog
                    open={openDialog}
                    modules={modules}
                    limitations={limitations}
                    onClose={() => setOpenDialog(false)} />
            )}
        </>
    )
}

export function PlanSelecion() {
    return (
        <Container maxWidth="lg">
            <Box pt={3} mb={5}>
                <Typography variant="h4" align="center">
                    Escolha um plano
                </Typography>
            </Box>
            <Box
                display="flex"
                justifyContent="space-between"
                gridGap="16px">
                {PLANOS_SISCHEF.map((plan) => (
                    <PlanCard key={plan.id} plan={plan} />
                ))}
            </Box>
        </Container>
    )
}